<template>
  <table
    v-if="products"
    class="ListTable table-fixed w-full text-center text-sm mt-6"
  >
    <thead class="bg-gray-200">
      <tr class="h-10">
        <td class="w-44">
          <div class="flex justify-center items-center">
            LOT#
            <template v-if="sort.lot === 'NONE'">
              <button class="sorting-button" @click="sortHandler('lot', 'ASC')">
                <sorting-default-icon/>
              </button>
            </template>
            <template  v-else-if="sort.lot === 'ASC'">
              <button class="sorting-button ascending" @click="sortHandler('lot', 'DESC')">
                <sorting-ascending-icon />
              </button>
            </template>
            <template v-else-if="sort.lot === 'DESC'">
              <button class="sorting-button descending" @click="sortHandler('lot', 'NONE')">
                <sorting-descending-icon />
              </button>
            </template>
          </div>
        </td>
        <td class="text-xs">상품명</td>
        <td class="w-40">카테고리</td>
        <td class="w-20">상태</td>
        <td class="w-20">표시상태</td>
        <td class="w-30">
          <div class="flex justify-center items-center">
            판매기간
            <template v-if="sort.startDate === 'NONE'">
              <button class="sorting-button" @click="sortHandler('startDate', 'ASC')">
                <sorting-default-icon/>
              </button>
            </template>
            <template  v-else-if="sort.startDate === 'ASC'">
              <button class="sorting-button ascending"  @click="sortHandler('startDate','DESC')">
                <sorting-ascending-icon />
              </button>
            </template>
            <template v-else-if="sort.startDate === 'DESC'">
              <button class="sorting-button descending" @click="sortHandler('startDate','NONE')">
                <sorting-descending-icon />
              </button>
            </template>
          </div>
        </td>
        <td class="w-28">판매옵션</td>
        <td class="w-36">
          <div class="flex justify-center items-center">
            시작가
            <template v-if="sort.startPrice === 'NONE'">
              <button class="sorting-button" @click="sortHandler('startPrice','ASC')">
                <sorting-default-icon/>
              </button>
            </template>
            <template  v-else-if="sort.startPrice === 'ASC'">
              <button class="sorting-button ascending"  @click="sortHandler('startPrice','DESC')">
                <sorting-ascending-icon />
              </button>
            </template>
            <template v-else-if="sort.startPrice === 'DESC'">
              <button class="sorting-button descending" @click="sortHandler('startPrice','NONE')">
                <sorting-descending-icon />
              </button>
            </template>
          </div>
        </td>
        <td class="w-36">
          <div class="flex justify-center items-center">
            BUY NOW 가격
            <template v-if="sort.buynowPrice === 'NONE'">
              <button class="sorting-button" @click="sortHandler('buynowPrice','ASC')">
                <sorting-default-icon/>
              </button>
            </template>
            <template  v-else-if="sort.buynowPrice === 'ASC'">
              <button class="sorting-button ascending"  @click="sortHandler('buynowPrice','DESC')">
                <sorting-ascending-icon />
              </button>
            </template>
            <template v-else-if="sort.buynowPrice === 'DESC'">
              <button class="sorting-button descending" @click="sortHandler('buynowPrice','NONE')">
                <sorting-descending-icon />
              </button>
            </template>
          </div>
        </td>
        <td class="w-30">
          <div class="flex justify-center items-center">
            등록일
            <template v-if="sort.createdAt === 'NONE'">
              <button class="sorting-button" @click="sortHandler('createdAt','ASC')">
                <sorting-default-icon/>
              </button>
            </template>
            <template  v-else-if="sort.createdAt === 'ASC'">
              <button class="sorting-button ascending"  @click="sortHandler('createdAt','DESC')">
                <sorting-ascending-icon />
              </button>
            </template>
            <template v-else-if="sort.createdAt === 'DESC'">
              <button class="sorting-button descending" @click="sortHandler('createdAt','NONE')">
                <sorting-descending-icon />
              </button>
            </template>
          </div>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr
        class="h-20 cursor-pointer"
        v-for="(item, index) in products"
        :key="index"
        @click="handleClickRow(item)"
      >
        <!-- 이미지 + LOT# -->
        <td>
          <div class="flex items-center">
            <div
              v-if="item.productImageList?.length > 0"
              class="w-15 h-15 bg-gray-100 bg-cover"
              :style="{
                backgroundImage: `url(${item.productImageList[0].url})`,
              }"
            ></div>
            <div
              v-else
              class="w-15 h-15 bg-gray-100 bg-cover"
              :style="{
                backgroundImage: `url(https://via.placeholder.com/120x120/F3F4F6?text=x)`,
              }"
            ></div>
            <div class="ml-4">{{ item.lot }}</div>
          </div>
        </td>

        <!-- 상품명 -->
        <td class="text-left">
          <div>{{ item.artistName }}</div>
          <div>{{ item.title }}</div>
        </td>

        <!-- 카테고리 -->
        <td class="break-all text-xs">
          <p v-if="item.categoryDepth1Id">
            {{ categoryDepth1Obj[item.categoryDepth1Id] }} > <br>
            {{ categoryDepth2Obj[item.categoryDepth2Id] }}
          </p>
          <p v-else>-</p>
        </td>
        <!-- 상태 -->
        <td>
          <mark :class="`sale-status__label ${salesStatus[item.salesStatus]?.colorClass}`">
            {{ salesStatus[item.salesStatus]?.label }}
          </mark>
        </td>

        <!-- 표시상태 -->
        <td>
          <mark class="label" v-if="!item.isInvisibled">공개중</mark>
          <mark class="label black" v-else>비공개</mark>
        </td>
        <!-- 판매기간 -->
        <td class="text-xs">
          <div v-if="item.startDate">
            {{ format(new Date(item.startDate), 'yyyy/MM/dd HH:mm') }}
          </div>
          <div v-else>-</div>
          <div v-if="item.startDate && !item.buynowOnly">
            {{ format(new Date(item.endDate), 'yyyy/MM/dd HH:mm') }}
          </div>
          <div v-else>-</div>
        </td>

        <!-- 판매옵션 -->
        <td class="text-xs">
          <div>
            <div v-if="item.enableBid">
              Bid Now<br>
              <mark v-if="item.enableBuyNow" class="sale-option__label">BUYNOW</mark>
            </div>
            <div v-else-if="item.buynowOnly">Buy now</div>
            <div v-else>-</div>
          </div>
          <mark v-if="item.makeOffer" class="sale-option__label red make-offer">MAKE OFFER</mark>
        </td>

        <!-- 시작가 -->
        <td class="cursor-pointer text-right">
          <p> {{ item.enableBid ? formatNumber(item.startPrice) : '-' }}</p>
        </td>
        <!-- buy now가격 -->
        <td class="cursor-pointer text-right">
          <p> {{ item.enableBuyNow || item.buynowOnly ? formatNumber(item.buynowPrice) : '-' }}</p>
        </td>

        <!-- 등록일-->
        <td class="text-xs">
          {{ format(new Date(item.createdAt), 'yyyy/MM/dd HH:mm') }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import { format } from 'date-fns';
import router from '@/router';
import useCategoryOptions from '@/mixins/useCategoryOptions';
import salesAndOrderStatus from '@/constants/salesAndOrderStatus';
import SortingDefaultIcon from '@/components/icons/SortingDefaultIcon.vue';
import SortingDescendingIcon from '@/components/icons/SortingDescendingIcon.vue';
import SortingAscendingIcon from '@/components/icons/SortingAscendingIcon.vue';

export default defineComponent({
  name: 'ListTable',
  components: {
    SortingAscendingIcon,
    SortingDefaultIcon,
    SortingDescendingIcon
  },
  props: {
    products: Object as PropType<any>,
    sortBy : String
  },

  setup(props, { emit }) {
    const { categoryDepth1Obj, categoryDepth2Obj } = useCategoryOptions();
    const { salesStatus } = salesAndOrderStatus();

    const sort = ref(
      {
        lot: 'NONE', // ASC. DESC
        startDate: 'NONE',
        startPrice: 'NONE',
        buynowPrice: 'NONE',
        createdAt: 'NONE',
      }
    )
    if (props.sortBy) {
      const [key, value] = props.sortBy.split(':')
      if (key && value) {
        sort.value[key] = value
      }
    }

    const sortHandler = (name, value) => {
      Object.keys(sort.value).forEach(key => sort.value[key] = 'NONE')
      sort.value[name] = value
      if (value === 'NONE') {
        emit('changeSort', undefined)
        return
      }
      emit('changeSort', `${name}:${value}`)
    }

    const handleClickRow = (item) => {
      router.push({
        path: '/app/product/register',
        query: {
          step: 3,
          productId: item.productId,
        },
      });
    };
    const formatNumber = (number) => {
      if (number === 0) {
        return '0원';
      }
      if (!number) {
        return '-';
      }
      return number.toLocaleString() + '원';
    };

    return {
      format,
      sort,
      sortHandler,
      formatNumber,
      handleClickRow,
      categoryDepth1Obj,
      categoryDepth2Obj,
      salesStatus,
    };
  },
});
</script>

<style lang="scss" scoped>
.ListTable {
  thead {
    tr {
      border: 1px solid #DDDDDD;
    }
  }
  tbody {
    tr {
      border-bottom: 1px dashed #dddddd;
    }
  }

  td {
    padding: 8px 10px;
  }
}
@import "../../../assets/style/label";
</style>
