<template>
  <form @submit.prevent="onClickSearch">
    <div class="ListFilter">
      <div class="FilterItem">
        <label>판매기간 : {{ params.start }} 부터</label>
        <input
          type="date"
          id="start"
          :value="params.start"
          @input="updateParams('start', $event.target.value)"
        />
      </div>
      <div class="FilterItem">
        <label>판매기간 : {{ params.end }} 까지</label>
        <input
          type="date"
          id="end"
          :value="params.end"
          @input="updateParams('end', $event.target.value)"
        />
      </div>

      <div v-if="categoryDepth1Options.length > 1" class="FilterItem">
        <label for="start">카테고리</label>
        <Select
          :value="params.categoryDepth1Id"
          :onChange="(e) => updateParams('categoryDepth1Id', Number(e.target.value) || undefined)"
          :options="categoryDepth1Options"
          :usePlaceholder="false"
        ></Select>
      </div>

      <div class="FilterItem">
        <label for="status">판매상태</label>
        <Select
          placeholder="전체"
          :value="params.salesStatus"
          :onChange="(e) => updateParams('salesStatus', e.target.value)"
          :options="saleOption"
          :usePlaceholder="false"
        ></Select>
      </div>

      <div class="FilterItem">
        <label for="option">판매옵션</label>
        <Select
          :value="params.enableBid"
          :onChange="(e) => updateParams('enableBid', e.target.value)"
          :options="enableBidOptions"
          :usePlaceholder="false"
        ></Select>
      </div>

      <div class="h-10 flex items-center">
        <input
          id="showInvisibled"
          :checked="params.showInvisibled"
          @change="(e) => updateParams('showInvisibled', e.target.checked)"
          type="checkbox"
          class="
            filter-checkbox
            block
            ml-4
            rounded
            border-gray-300
            text-blue-sky
            focus:ring
            focus:ring-offset-0
            focus:ring-blue-sky
            focus:ring-opacity-10
          "
        />
        <label for="showInvisibled" class="ml-2 cursor-pointer text-sm">
          비공개 표시
        </label>
      </div>
      <button
        :class="['Filter__applyButton', isFilterOn && 'isApplied']"
        @click="onClickApply"
        type="button"
      >
        필터적용
      </button>
      <div class="ml-auto">
        <button
          type="button"
          class="mr-2 bg-black text-white text-sm h-16 w-30"
          @click.stop.prevent="() => $router.push(`/app/product/bulkRegister`)"
        >
          대량등록
        </button>
        <button
          type="button"
          class="bg-primary text-white text-sm h-16 w-30"
          @click.stop.prevent="onClickRegister"
        >
          상품등록
        </button>
      </div>

    </div>

    <div class="flex w-full mt-6">
      <input
        type="text"
        class="
          Filter__textInput
          border border-black
          flex-1
          h-10
          py-3
          px-10
          text-xs
        "
        placeholder="LOT No. 작가/브랜드명, 상품명으로 검색"
        :value="params.keyword"
        @change="(e) => updateParams('keyword', e.target.value)"
      />
      <button class="w-30 h-10 ml-4 bg-black text-white text-xs" type="submit">
        검색
      </button>
    </div>
  </form>
</template>

<script lang="ts">
import Select from '@/components/Select.vue';
import { defineComponent, PropType } from 'vue';
import useStatusOptions from '@/mixins/useStatusOptions';
import { ProductListParams } from './index.vue';
import useCategoryOptions from '@/mixins/useCategoryOptions';
import useEnableBidOptions from '@/mixins/useEnableBidOptions';

export default defineComponent({
  name: 'ListFilter',
  components: {
    Select,
  },
  props: {
    params: {
      type: Object as PropType<ProductListParams>,
      required: true,
    },
    handleChangeFilterParams: { type: Function, required: true },
    onClickApply: { type: Function, required: true },
    onClickSearch: { type: Function, required: true },
    onClickRegister: { type: Function, required: true },
    isFilterOn: Boolean,
  },
  methods: {},
  setup(props) {
    const { categoryDepth1Options } = useCategoryOptions();
    const { statusOptionsForSearch } = useStatusOptions();
    const { enableBidOptions } = useEnableBidOptions();
    const saleOption = [
      { value: '', label: '전체' },
      { value: 'sale', label: '판매중' },
      { value: 'sold_out', label: '판매완료' },
      { value: 'bid_complete', label: '낙찰' },
      { value: 'time_out', label: '유찰' },
      { value: 'bid_payment_time_out', label: '낙찰취소' },
    ];

    const updateParams = (key: string, value: any) => {
      props.handleChangeFilterParams(key, value);
    };

    return {
      updateParams,
      statusOptionsForSearch,
      categoryDepth1Options,
      enableBidOptions,
      saleOption
    };
  },
});
</script>

<style lang="scss" scoped>
.ListFilter {
  display: flex;
  align-items: flex-end;
}

.FilterItem {
  width: 150px;
  display: inline-flex;
  flex-direction: column;

  & + & {
    margin-left: 6px;
  }

  & > label {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 8px;
  }

  & > input {
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    background: #ffffff;
    border: 1px solid #dddddd;
    padding: 0 16px;
    height: 40px;
    background: url('../../../assets/ico_selectbar_arrow.png') no-repeat;
    background-size: 10px;
    background-position: calc(100% - 14px) center;

    &.isNotSelected {
      color: #999;
    }
  }

  & > input {
    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      background: transparent;
      bottom: 0;
      color: transparent;
      cursor: pointer;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  & > select {
    &.isNotSelected {
      color: #999;
    }
  }
}

.Filter__applyButton {
  width: 110px;
  text-align: left;
  margin-left: 6px;
  font-size: 14px;
  height: 40px;
  padding-left: 44px;
  background-size: 28px;
  background-image: url('../../../assets/ico_filter_default.svg');
  background-repeat: no-repeat;
  background-position: 10px center;
  box-sizing: border-box;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid #ff6363;
    background-image: url('../../../assets/ico_filter_hover.svg');
  }

  &.isApplied {
    background-color: #ff6363;
    background-image: url('../../../assets/ico_filter_applied.svg');
    color: #fff;
  }
}

.Filter__textInput {
  background-image: url('../../../assets/ico_Search.svg');
  background-repeat: no-repeat;
  background-position: 14px center;
  &::placeholder {
    color: #888;
  }
}

.filter-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  background-color: #fff;
  border-color: #D9D9D9;
  border-width: 1px;
  --tw-shadow: 0 0 #0000;
  &:checked {
    background-image: url('../../../assets/input_check-primary.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
}
</style>
