<template>
  <Container>
    <!-- Filter -->
    <ListFilter
      :params="filterParams"
      :handleChangeFilterParams="handleChangeFilterParams"
      :onClickApply="toggleApplyFilters"
      :onClickSearch="executeSearch"
      :onClickRegister="handleClickRegister"
      :isFilterOn="isFilterOn"
    />

    <ListTable
      :products="productList.data"
      :sortBy="filterParams.sortBy"
      @changeSort="handleChangeSort"
    ></ListTable>

    <div class="mt-10">
      <Pagination
        :initialPage="filterParams.page"
        :pageSize="parseInt(filterParams.pageSize)"
        :onChange="handleChangePage"
        :onChangePageSize="handleChangePageSize"
        :total="productList.total"
      ></Pagination>
    </div>
  </Container>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch, reactive, toRef } from 'vue';
import { useRoute } from 'vue-router';
import Container from '@/components/Container.vue';
import Pagination from '@/components/Pagination/index.vue';
import router from '@/router/index';
import ListFilter from './ListFilter.vue';
import ListTable from './ListTable.vue';
import partnerAPI from '@/service/partnerAPI';
import format from 'date-fns/format/index.js';
import datePattern from '@/constants/datePattern';

export type ProductListParams = {
  start?: string;
  end?: string;
  categoryDepth1Id?: number;
  status?: string;
  enableBid?: boolean;
  showInvisibled?: boolean;
  keyword?: string;
  page: number;
  pageSize: number;
  sortBy?: string
};

export default defineComponent({
  name: 'Product',
  components: {
    Container,
    ListFilter,
    ListTable,
    Pagination,
  },
  props: {},
  setup() {
    const route = useRoute();
    const query = route.query;

    const filterParams = reactive<ProductListParams>({
      start: (query.start as string) || '',
      end: (query.end as string) || '',
      categoryDepth1Id: Number(query.categoryDepth1Id) || undefined,
      status: (query.status as string) || '',
      enableBid: query.enableBid === 'true' ? true : query.enableBid === 'false' ? false : undefined,
      showInvisibled: query.showInvisibled === 'true' ? true : query.showInvisibled === 'false' ? false : undefined,
      keyword: (query.keyword as string) || '',
      page: Number(query.page) || 0,
      pageSize: Number(query.pageSize) || 10,
      sortBy: (query.sortBy as string) || undefined
    });

    const isFilterOn = ref(query.isFilter === 'true');

    const productList = ref({
      success: true,
      data: [],
      message: '',
      total: 0,
    });

    const toggleApplyFilters = () => {
      isFilterOn.value = !isFilterOn.value;
      executeSearch();
    };

    const handleChangeFilterParams = (key: string, value: any) => {
      console.log('key, value', key, value);
      filterParams[key] = value;
    };

    const handleChangePage = (page: number) => {
      filterParams.page = page - 1;
      executeSearch();
    };

    const handleChangePageSize = (pageSize: number) => {
      filterParams.page = 0;
      filterParams.pageSize = pageSize;
      executeSearch();
    };

    const handleChangeSort = (sortBy) => {
      filterParams.sortBy = sortBy
      executeSearch()
    }

    /**
     * 검색 실행
     */
    const executeSearch = () => {
      // fetchProductLists();
      router.push({
        path: window.location.pathname,
        query: {
          start: filterParams.start || '',
          end: filterParams.end || '',
          categoryDepth1Id: filterParams.categoryDepth1Id || '',
          status: filterParams.status || '',
          enableBid: String(filterParams.enableBid) || '',
          showInvisibled: String(filterParams.showInvisibled) || '',
          keyword: filterParams.keyword || '',
          page: filterParams.page || 0,
          pageSize: filterParams.pageSize || 10,
          isFilter: String(isFilterOn.value),
          sortBy: String(filterParams.sortBy) || ''
        },
      });
    };

    const handleClickRegister = () => {
      router.push({
        path: '/app/product/register',
        query: {
          step: 1,
        },
      });
    };

    const getAppliedFilterParams = () =>
      isFilterOn.value
        ? {
            ...filterParams,
            start: filterParams.start
              ? format(
                  new Date(filterParams.start),
                  datePattern.INPUT_DATE + ' 00:00:00'
                )
              : undefined,
            end: filterParams.end
              ? format(
                  new Date(filterParams.end),
                  datePattern.INPUT_DATE + ' 23:59:59'
                )
              : undefined,
          }
        : {
            // 필터가 적용되어있지 않으면 타이틀만 사용한다
            keyword: filterParams.keyword,
            sortBy: filterParams.sortBy,
            page: filterParams.page,
            pageSize: filterParams.pageSize,
          };

    const fetchProductLists = async () => {
      console.group('fetchProductLists');
      console.log('finalFilterParams', getAppliedFilterParams());
      console.groupEnd();

      try {
        const { data } = await partnerAPI.partnerProduct.listUsingGET4(
          getAppliedFilterParams()
        );
        productList.value = data as any;
      } catch ({ response }) {
        console.error(response.data);
        alert(response.data.message);
      }
    };

    const startRef = toRef(filterParams, 'start');
    const endRef = toRef(filterParams, 'end');
    const categoryIdRef = toRef(filterParams, 'categoryDepth1Id');
    const statusRef = toRef(filterParams, 'status');
    const enableBidRef = toRef(filterParams, 'enableBid');
    const showInvisibledRef = toRef(filterParams, 'showInvisibled');

    watch(
      [isFilterOn, startRef, endRef, categoryIdRef, statusRef, enableBidRef, showInvisibledRef],
      ([isFilterOn]) => {
        // 필터 활성화 상태에서 watch하는 파라미터가 업데이트되면 검색 실행
        if (isFilterOn) {
          executeSearch();
        }
      }
    );

    onMounted(fetchProductLists);

    watch([filterParams, isFilterOn], (newFilter, newIsFilterOn) => {
      console.log('watch filter', newFilter, newIsFilterOn);
      fetchProductLists();
    });

    return {
      filterParams,
      productList,
      toggleApplyFilters,
      handleChangePage,
      handleChangePageSize,
      handleChangeSort,
      executeSearch,
      handleClickRegister,
      isFilterOn,
      handleChangeFilterParams,
    };
  },
});
</script>

<style lang="scss" scoped></style>
